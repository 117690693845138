var KEYCODE_ENTER = 13;
var KEYCODE_ESC = 27;

var app = window.app || {};

app.toggleSearchUp = function(e) {
    let lupa = document.querySelector('.lupa')
    let btn_lupa = document.querySelector('.btn-lupa')
    var elem = document.querySelector('.search-up'),
        Style = window.getComputedStyle(elem),
        top = Style.getPropertyValue('top');
    if (lupa.textContent == 'Fechar') {
        document.activeElement.blur();
        elem.style.top = '-100%';
        lupa.textContent = 'Buscar';
        elem.style.opacity = '0';
        document.body.classList.remove('overflow-hidden')
        btn_lupa.classList.remove('btn-secondary')
        btn_lupa.classList.add('btn-outline-secondary')
    } else {
        elem.style.top = window.scrollY+68+'px';
        lupa.textContent = 'Fechar';
        elem.style.opacity = '1';
        document.body.classList.add('overflow-hidden')
        btn_lupa.classList.add('btn-secondary')
        btn_lupa.classList.remove('btn-outline-secondary')
        setTimeout(() => {
            document.querySelector(".search-desktop").focus();
        }, "1000")
    }
}

app.toggleSearchMobile = function(e) {
    var elem = document.querySelector('.search-mobile'),
    Style = window.getComputedStyle(elem),
    left = Style.getPropertyValue('left');
    if (left == '0px') {
        elem.style.left = '-100%';
        elem.style.opacity = '0';
        document.body.classList.remove('overflow-hidden')
    } else {
        elem.style.left = '0';
        elem.style.opacity = '1';
        document.body.classList.add('overflow-hidden')
    }
}
